import React from 'react';
import { Icon } from '../../tokens/icons';
import { IconName } from '../../tokens/icons/types';
import cn from 'clsx';
import s from './PatternTitleSubtitle.module.css';

interface PatternTitleSubtitleProps {
    title?: string;
    titleSize?: 'small' | 'medium' | 'large';
    subTitle?: string;
    subTitleSize?: 'small' | 'medium' | 'large';
    titleIcon?: IconName;
    billboardType?: 'static' | 'carousel' | 'video';
    titleTag?: 'h1' | 'h2';
}

export const PatternTitleSubtitle = ({
    title,
    subTitle,
    titleIcon = '',
    titleSize = 'large',
    subTitleSize = 'large',
    billboardType,
    titleTag,
}: PatternTitleSubtitleProps) => {
    const defaultTitleSize = titleSize || 'large';
    const defaultSubTitleSize = subTitleSize
        ? subTitleSize
        : titleSize || 'large';
    const titleClassName = cn(s.titleSizeRoot, {
        [s.titleSizeSmall]: titleSize === 'small',
        [s.titleSizeMedium]: titleSize === 'medium',
        [s.titleSizeLarge]: defaultTitleSize === 'large',
    });
    const subTitleClassName = cn(s.subTitleSizeRoot, {
        [s.subTitleSizeSmall]: defaultSubTitleSize === 'small',
        [s.subTitleSizeMedium]: defaultSubTitleSize === 'medium',
        [s.subTitleSizeLarge]: defaultSubTitleSize === 'large',
    });
    let titleTagType = titleTag || 'h2';

    return (
        <div
            className={cn(
                billboardType && billboardType === 'static' && s.setWidth
            )}
        >
            {title && titleTagType === 'h1' && (
                <h1 className={titleClassName}>
                    {title}
                    {titleIcon && <Icon className={s.icon} icon={titleIcon} />}
                </h1>
            )}
            {title && titleTagType === 'h2' && (
                <h2 className={titleClassName}>
                    {title}
                    {titleIcon && <Icon className={s.icon} icon={titleIcon} />}
                </h2>
            )}
            {subTitle && <h2 className={subTitleClassName}>{subTitle}</h2>}
        </div>
    );
};

export default PatternTitleSubtitle;
