export const articleByHandleQuery = (handle) => `{
    blogByHandle(handle: "stories") {
        articleByHandle(handle: "${handle}") {
            id
            handle
            title
            handle
            content
            tags
            publishedAt
            image {
                transformedSrc
            }
            seo {
              description
              title
            }
        }
    }
}
`;

export const articlesQuery = (first, query) => `{
    blogByHandle(handle: "stories") {
        articles(first: ${first}, query: "${query}", sortKey: PUBLISHED_AT, reverse: true) {
            edges {
                node {
                    id
                    handle
                    title
                    handle
                    content
                    tags
                    publishedAt
                    image {
                        transformedSrc
                    }
                }
            }
            pageInfo {
                endCursor
                hasNextPage
            }  
        }
    }
}
`;

export const articlesQueryLite = (first, query) => `{
    blogByHandle(handle: "stories") {
        articles(first: ${first}, query: "${query}", sortKey: PUBLISHED_AT, reverse: true) {
            nodes {
                id
                handle
                title
                handle
                content
                tags
                publishedAt
                image {
                    transformedSrc
                }
            }
        }
    }
}
`;
