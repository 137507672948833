import { gql } from 'graphql-request';
import { PRODUCT_FIELDS } from '@app/lib/shopify/queries/fragments/product-fields';

const productByHandle = gql`
    query ProductQuery($country: CountryCode, $handle: String!)
    @inContext(country: $country) {
        product(handle: $handle) {
            ...ProductFields
        }
    }
    ${PRODUCT_FIELDS}
`;
export default productByHandle;
