import { Context, Environment, Language } from './Context';
import staging from '@app/lib/store-context/staging';
import production from '@app/lib/store-context/production';

const environment: Environment =
    process.env.NEXT_PUBLIC_STAGING_ENV === 'true' ? staging : production;

export function getAllEnvironmentContexts(): Context[] {
    const { contexts } = environment;
    return contexts;
}
