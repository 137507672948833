import { gql } from 'graphql-request';
const collections = gql`
    query CollectionsQuery(
        $country: CountryCode
        $productFilters: [ProductFilter!]
        $after: String
    ) {
        collections(first: 250, after: $after) @inContext(country: $country) {
            nodes {
                handle
                title
                products(first: 250, filters: $productFilters) {
                    nodes {
                        handle
                    }
                }
            }
            pageInfo {
                endCursor
                hasNextPage
            }
        }
    }
`;
export default collections;
