import React from 'react';
import LinkManager, { LinkerComponent } from '../link-manager/LinkManager';
import { BreadcrumbList, WithContext } from 'schema-dts';
import cn from 'clsx';
import s from './Breadcrumb.module.css';

export type BreadcrumbNode = {
    label: string;
    hrefLink?: string;
};

export interface BreadcrumbProps {
    path: BreadcrumbNode[];
    lang: string;
    Linker?: LinkerComponent;
}

export const Breadcrumb = ({
    path,
    lang,
    Linker = LinkManager,
}: BreadcrumbProps) => {
    const activePrefix = (lang as string)?.replace(/en-/g, '').toLowerCase();
    const jsonLd: WithContext<BreadcrumbList> = {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [
            {
                '@type': 'ListItem',
                position: 1,
                name: `${path[0]?.label}`,
                item: `https://maap.cc/${activePrefix}${path[0]?.hrefLink}`,
            },
            {
                '@type': 'ListItem',
                position: 2,
                name: `${path[1]?.label}`,
                item: `https://maap.cc/${activePrefix}${path[1]?.hrefLink}`,
            },
            {
                '@type': 'ListItem',
                position: 3,
                name: `${path[2]?.label}`,
                item: `https://maap.cc/${activePrefix}${path[2]?.hrefLink}`,
            },
        ],
    };
    return (
        <div className={s.breadcrumbsRoot}>
            {path.map((node, index) => {
                return (
                    <div
                        key={index}
                        className={cn(
                            s.breadcrumbsItem,
                            'mmds-copy-three-detail'
                        )}
                    >
                        {node.hrefLink && (
                            <Linker
                                className={s.breadcrumbLink}
                                href={node.hrefLink || '/'}
                                lang={lang}
                                title={`Link to ${node.label}`}
                            >
                                {node.label}
                            </Linker>
                        )}
                        {!node.hrefLink && node.label}
                    </div>
                );
            })}
            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                    __html: JSON.stringify(jsonLd),
                }}
            />
        </div>
    );
};

export default Breadcrumb;
