import ExpandableTextExpandedArea from './expandable-text-expanded-area';
import s from './expandable-text.module.css';
import cn from 'clsx';

export interface ExpandableTextProps {
    context?: string;
    shortText?: string;
    fullText?: string;
}

export function ExpandableText({
    context,
    shortText,
    fullText,
}: ExpandableTextProps) {
    return (
        <div className={s.container}>
            {context && (
                <h1
                    className={cn(s.context, 'mmds-component-one-detail')}
                    dangerouslySetInnerHTML={{
                        __html: context,
                    }}
                />
            )}
            <div>
                {shortText && (
                    <span
                        style={{ whiteSpace: 'pre-line' }}
                        className={cn(s.shortText, 'mmds-copy-one')}
                        dangerouslySetInnerHTML={{
                            __html: shortText,
                        }}
                    />
                )}
                {fullText && <ExpandableTextExpandedArea text={fullText} />}
            </div>
        </div>
    );
}
