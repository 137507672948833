import React from 'react';
import Image from 'next/image';
import dayjs from 'dayjs';
import Link from 'next/link';
import PropTypes from 'prop-types';
import { brandBlack } from 'config/styles/colours';
import { breakpointSmall } from 'config/styles/breakpoints';
import localisePath from '@app/lib/helpers/localise-path';
import { Button } from 'mmds';

const BlogArticleCard = ({ swiper = false, handle, lang, category = '', title, imageUrl, body, published_at }) => {
    const limitWords = (text, wordLimit) => {
        const words = text.split(/\s+/);
        return words.slice(0, wordLimit).join(' ') + (words.length > wordLimit ? '...' : '');
    }

    const formattedBody = limitWords(body, 60);

    return (
        <>
            <Link
                as={localisePath(`/stories/${category}/${handle}`, lang)}
                href={localisePath(`/stories/${category}/${handle}`, lang)}
                legacyBehavior
            >
                <a className="blog_article_link">
                    <div className="blog_article_card">
                        <div className="blog_article_card__image">
                            <div className="mmds-component-one-detail">{category?.toUpperCase()}</div>
                            <Image
                                src={imageUrl}
                                alt={title}
                                width={480}
                                height={360}
                                sizes="(max-width: 768px) 100vw, 33vw"
                                style={{
                                    width: '100%',
                                    height: 'auto',
                                    aspectRatio: '5/4',
                                    objectFit: 'cover'
                                }}
                            />
                        </div>
                        <div className="blog_article_card__summary">
                            <div>
                                <div className="blog_article_card__heading">
                                    <div className="mmds-copy-three heading">{title}</div>
                                    <div className="blog_article_card__date mmds-copy-three">
                                        {dayjs(published_at).format('MMM.DD.YY').toUpperCase()}
                                    </div>
                                </div>
                                <div className="blog_article_card__description mmds-copy-three-serif">
                                    {swiper ? formattedBody : body}
                                </div>
                            </div>
                            <div className="blog_article_card__more">
                                <Button variant="text" textVariantPadding={false} label="Read More" />
                            </div>
                        </div>
                    </div>
                </a>
            </Link>
            <style jsx>
                {`
                    .blog_article_card {
                        display: flex;
                        flex-direction: column;
                        height: 100%;
                    }
                    
                    .blog_article_card:hover {
                        background-color: #f7f7f7;
                    }
                        
                    .blog_article_link:hover {
                        background-color: #f7f7f7;
                    }
                    
                    .blog_article_card__image {
                        position: relative;
                    }
                    
                    .blog_article_card__summary {
                        display: flex;
                        justify-content: space-between;
                        flex-direction: column;
                        height: ${swiper ? '230px' : 'inherit'};
                        padding: var(--spacing-size-four) var(--spacing-size-three);
                    }

                    .blog_article_card__image > div {
                        color: #fff;
                        position: absolute;
                        padding: 12px;
                    }

                    .blog_article_card__image > img {
                        width: 100%;
                        display: block;
                    }

                    .blog_article_card__heading {
                        display: flex;
                        justify-content: space-between;
                        padding-bottom: var(--spacing-size-five);
                        align-items: start;
                        flex-grow: 1;
                        font-size: 1em;
                    }
                    
                    .heading {
                        text-transform: uppercase;
                    }

                    .blog_article_card__date {
                        flex-basis: 50%;
                        text-align: right;
                        color: var(--text-secondary);
                    }

                    .blog_article_card__description {
                        padding-bottom: var(--spacing-size-three);
                    }

                    a {
                        color: ${brandBlack};
                        text-decoration: none;
                    }

                    @media (max-width: ${breakpointSmall}) {
                        .blog_article_card {
                            width: 100%;
                            padding: 0;
                        }
                        
                        .blog_article_card__summary {
                            height: ${swiper ? '280px' : 'inherit'};
                        }
                    }
                `}
            </style>
        </>
    );
};

BlogArticleCard.propTypes = {
    handle: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    published_at: PropTypes.string,
};

export default BlogArticleCard;
