import { getAllEnvironmentContexts } from '@app/lib/store-context/get-all-environment-contexts';
import { Language } from '@app/lib/store-context/Context';
import { getContext } from '@app/lib/store-context/get-context';

// TODO: Unit test

function removeLocaleFromPath(path: string): string {
    const contexts = getAllEnvironmentContexts();
    for (const context of contexts) {
        path = path
            .replace(`/${context.countryCode.toLowerCase()}/`, '')
            .replace(`/${context.countryCode}`, '')
            .replace(`/${context.language}`, '');
    }
    return path;
}

export default function localisePath(
    targetPath: string,
    lang: Language
): string {
    if (!lang) {
        console.warn(
            'Localise path function/Linker component warning.... No lang provided!'
        );
    }

    // return url if it has http in it
    if (targetPath && targetPath.indexOf('http') > -1) {
        return targetPath;
    }

    if (!targetPath) {
        return '';
    }

    // Otherwise clear any locales from the path and add the
    // new locale as a country code.
    const cleanPath = removeLocaleFromPath(targetPath);

    // en-AU is wierd and shouldn't have a locale in the URL.
    // Clear any locales from the path and sent it back out.
    if (lang === 'en-AU') {
        return cleanPath;
    }

    const context = getContext(lang);
    if (cleanPath.slice(0, 1) === '/') {
        return `/${context.countryCode.toLowerCase()}${cleanPath}`;
    }
    return `/${context.countryCode.toLowerCase()}/${cleanPath}`;
}
