import { gql } from 'graphql-request';

export const COLLECTION_PRODUCT_FIELDS = gql`
    fragment CollectionProductFields on Product {
        id
        title
        handle
        tags
        collections(first: 25) {
            edges {
                node {
                    handle
                    title
                }
            }
        }
        parentSKU: metafield(namespace: "custom", key: "parent_sku") {
            value
        }
        isNewArrival: metafield(namespace: "custom", key: "is_new_arrival") {
            value
        }
        images(first: 25) {
            edges {
                node {
                    altText
                    height
                    transformedSrc
                }
            }
        }
        media(first: 25) {
            edges {
                node {
                    ... on Video {
                        id
                        previewImage {
                            altText
                            transformedSrc
                            width
                            height
                        }
                        sources {
                            mimeType
                            url
                        }
                    }
                }
            }
        }
        variants(first: 15) {
            edges {
                node {
                    id
                    sku
                    title
                    availableForSale
                    quantityAvailable
                    image {
                        transformedSrc
                    }
                    priceV2 {
                        amount
                        currencyCode
                    }
                    compareAtPriceV2 {
                        amount
                    }
                    selectedOptions {
                        name
                        value
                    }
                }
            }
        }
        videoURL: metafield(
            namespace: "custom"
            key: "pdp_media_gallery_video"
        ) {
            value
        }
    }
`;
