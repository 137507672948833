import { gql } from 'graphql-request';

const productsByMetafield = gql`
    query ProductsByMetafield(
        $collectionHandle: String!
        $filters: [ProductFilter!]
    ) {
        collection(handle: $collectionHandle) {
            handle
            products(first: 25, filters: $filters) {
                edges {
                    node {
                        id
                        title
                        handle
                        images(first: 25) {
                            edges {
                                node {
                                    transformedSrc
                                    altText
                                }
                            }
                        }
                        variants(first: 25) {
                            edges {
                                node {
                                    sku
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default productsByMetafield;
