'use client';

import React, { useEffect, useState } from 'react';
import { Language } from '@app/lib/store-context/Context';
import { getClient } from '@app/lib/shopify';
import { articlesQuery } from '@lib/gql/article';
import { getContext } from '@app/lib/store-context/get-context';
import BlogArticleCard from '@components/BlogArticleCard';
import { ArticleEdge } from 'shopify-storefront-api-typings';
import s from './stories.module.css';
import { Button, ButtonVariant, CTASet, PatternCTASet } from 'mmds';
import cn from 'clsx';
import upperCase from 'lodash.uppercase';
import kebabCase from 'lodash.kebabcase';
import replace from 'lodash.replace';
import localisePath from '@app/lib/helpers/localise-path';

interface Props {
    lang: Language;
    collection?: string;
}

const MAX_ARTICLE_LOAD = 16;

const Stories = ({ lang, collection }: Props) => {
    const context = getContext(lang as Language);
    const [articles, setArticles] = useState<ArticleEdge[]>([]);
    const [hasMore, setHasMore] = useState(false);
    const [count, setCount] = useState(MAX_ARTICLE_LOAD);
    const [categories, setCategories] = useState([]);
    const query = collection ? `tag:\\"collection:${collection}\\"` : 'tag:*';
    useEffect(() => {
        getClient(null)
            .request.send({
                query: articlesQuery(count, query),
            })
            .then((response) => {
                if (response?.blogByHandle?.articles?.edges?.length) {
                    const shopifyArticles =
                        response?.blogByHandle?.articles?.edges;
                    const hasMore =
                        response?.blogByHandle?.articles?.pageInfo.hasNextPage;
                    setArticles(shopifyArticles);
                    setHasMore(hasMore);
                }
            });
    }, [context, count, query]);

    useEffect(() => {
        const storiesCache = localStorage.getItem('stories:cache');

        if (storiesCache) {
            const stories = JSON.parse(storiesCache).stories;
            const categories = stories?.map(({ category }) =>
                kebabCase(category)
            );
            setCategories(categories);
        }
    }, []);

    const CTAset: CTASet = categories?.map((category) => {
        const variant =
            collection === category
                ? 'discover'
                : ('collection' as ButtonVariant);

        return {
            variant,
            label: `#${replace(category, /-/g, '')}`,
            hrefLink: localisePath(`/stories/${category}`, lang),
        };
    });

    CTAset.unshift(
        ...[
            {
                variant: 'secondaryGhost' as ButtonVariant,
                label: 'Topics :',
                hrefLink: '',
            },
            {
                variant: collection
                    ? 'collection'
                    : ('discover' as ButtonVariant),
                label: 'All',
                icon: 'northeast',
                hrefLink: '/stories',
            },
        ]
    );

    return (
        <>
            <div className={s.wrapper}>
                <div className={cn('mmds-title-two-alt', s.heading, s.title)}>
                    {upperCase(collection) || 'STORIES'}
                </div>
                <div className={s.topicsWrapper}>
                    <PatternCTASet set={CTAset} lang={lang} />
                </div>
                <div className={s.grid}>
                    {articles.map((article, index) => {
                        const {
                            handle,
                            title,
                            image,
                            content,
                            publishedAt,
                            tags,
                        } = article.node;
                        const firstTag = tags.find((tag) =>
                            tag.includes('collection')
                        );
                        const tagCollection = firstTag?.split(':')[1];
                        const category = collection
                            ? collection
                            : tagCollection;

                        return (
                            <BlogArticleCard
                                key={index}
                                category={category}
                                handle={handle}
                                title={title}
                                imageUrl={image?.transformedSrc}
                                body={content}
                                published_at={publishedAt}
                                lang={lang}
                            />
                        );
                    })}
                </div>
            </div>
            {hasMore && (
                <Button
                    className={s.loadMore}
                    onClick={() => setCount((prev) => prev + MAX_ARTICLE_LOAD)}
                >
                    Load More
                </Button>
            )}
        </>
    );
};

export default Stories;
