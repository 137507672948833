import s from './capsule-card.module.css';
import { Button } from '../button';
import { IconName } from '../../tokens/icons';

export interface CapsuleCardProps {
    ctaText: string;
    ctaIcon?: IconName;
    imageSrc: string;
    imageAlt: string | null;
    imageWidth?: number;
    imageHeight?: number;
}

export function CapsuleCard({
    ctaText,
    ctaIcon,
    imageSrc,
    imageAlt,
    imageWidth,
    imageHeight,
}: CapsuleCardProps): JSX.Element {
    return (
        <div className={s.root}>
            {!!imageSrc && (
                <img
                    className={s.image}
                    src={imageSrc}
                    alt={imageAlt || 'Promoted MAAP capsule collection image'}
                    width={imageWidth}
                    // height={imageHeight}
                />
            )}
            <Button
                variant="link_alt"
                padding="zero"
                icon={ctaIcon}
                className={s.capsuleCardButtonPadding}
            >
                {ctaText}
            </Button>
        </div>
    );
}
