import { gql } from 'graphql-request';

const pageByHandle = gql`
    query PageByHandle($handle: String!) {
        page(handle: $handle) {
            id
            title
            handle
            body
            updatedAt
            metafields(
                identifiers: [
                    { namespace: "custom", key: "global_layout_config" },
                    { namespace: "custom", key: "global_story_config" }
                ]
            ) {
                value
                key
                namespace
            }
        }
    }
`;

export default pageByHandle;
