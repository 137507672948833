import { gql } from 'graphql-request';

export const PRODUCT_FIELDS = gql`
    fragment ProductFields on Product {
        id
        handle
        title
        description
        tags
        availableForSale
        productType
        seo {
            description
            title
        }
        priceRange {
            minVariantPrice {
                amount
            }
        }
        images(first: 25) {
            edges {
                node {
                    altText
                    transformedSrc
                    height
                    width
                }
            }
        }
        media(first: 1) {
            edges {
                node {
                    ... on Video {
                        id
                        previewImage {
                            altText
                            transformedSrc
                            width
                            height
                        }
                        sources {
                            mimeType
                            url
                        }
                    }
                }
            }
        }
        options {
            name
            values
        }
        collections(first: 25) {
            edges {
                node {
                    handle
                    title
                }
            }
        }
        isNewArrival: metafield(namespace: "custom", key: "is_new_arrival") {
            value
        }
        parentSKU: metafield(namespace: "custom", key: "parent_sku") {
            value
        }
        metafields(
            identifiers: [
                { namespace: "product", key: "seo_description" }
                { namespace: "product", key: "seo_title" }
                { namespace: "product", key: "sizing" }
                { namespace: "product", key: "short_description" }
                { namespace: "product", key: "long_description" }
                { namespace: "breadcrumb_level_1", key: "handle" }
                { namespace: "breadcrumb_level_1", key: "title" }
                { namespace: "breadcrumb_level_2", key: "handle" }
                { namespace: "breadcrumb_level_2", key: "title" }
                { namespace: "breadcrumb_level_3", key: "handle" }
                { namespace: "breadcrumb_level_3", key: "title" }
                { namespace: "related_products", key: "complete_the_look" }
                { namespace: "custom", key: "long_form_pdp_description" }
                { namespace: "custom", key: "detail_icon_1" }
                { namespace: "custom", key: "detail_icon_2" }
                { namespace: "custom", key: "detail_icon_3" }
                { namespace: "custom", key: "detail_icon_4" }
                { namespace: "custom", key: "detail_icon_5" }
                { namespace: "custom", key: "detail_icon_6" }
                { namespace: "custom", key: "detail_icon_7" }
                { namespace: "custom", key: "feature_image_1" }
                { namespace: "custom", key: "feature_image_2" }
                { namespace: "custom", key: "feature_image_3" }
                { namespace: "custom", key: "feature_image_4" }
                { namespace: "custom", key: "feature_image_5" }
                { namespace: "custom", key: "feature_image_6" }
                { namespace: "custom", key: "feature_image_7" }
                { namespace: "custom", key: "feature_block_hex_code" }
                {
                    namespace: "custom"
                    key: "light_text_or_dark_text_linked_to_hex_code_value_field"
                }
                { namespace: "custom", key: "temperature_range_c" }
                { namespace: "custom", key: "temperature_range_f" }
                { namespace: "custom", key: "care_instructions" }
                { namespace: "custom", key: "riding_conditions" }
                { namespace: "custom", key: "product_weight" }
                { namespace: "custom", key: "material_composition" }
                { namespace: "custom", key: "gender_multiselect" }
                { namespace: "custom", key: "product_sizing" }
                { namespace: "custom", key: "parent_sku" }
                { namespace: "custom", key: "prismic_document_uids" }
                { namespace: "custom", key: "contains_prismic_component" }
                { namespace: "custom", key: "pdp_media_gallery_video" }
                { namespace: "custom", key: "pdp_feature_component_video" }
                {
                    namespace: "custom"
                    key: "pdp_feature_component_video_location"
                }
                { namespace: "custom", key: "maap_fit" }
            ]
        ) {
            value
            key
            namespace
        }
        variants(first: 50) {
            edges {
                node {
                    id
                    sku
                    title
                    availableForSale
                    quantityAvailable
                    image {
                        transformedSrc
                    }
                    priceV2 {
                        amount
                        currencyCode
                    }
                    compareAtPriceV2 {
                        amount
                    }
                    selectedOptions {
                        name
                        value
                    }
                    product {
                        handle
                        title
                        images(first: 1) {
                            edges {
                                node {
                                    transformedSrc
                                }
                            }
                        }
                    }
                    metafields(
                        identifiers: [
                            { namespace: "notify_me", key: "date" }
                            { namespace: "preorder", key: "date" }
                        ]
                    ) {
                        value
                        key
                        namespace
                    }
                }
            }
        }
    }
`;
