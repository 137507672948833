import { gql } from 'graphql-request';
const collectionByHandleProductHandlesOnly = gql`
    query CollectionQuery($country: CountryCode, $handle: String!)
    @inContext(country: $country) {
        collection(handle: $handle) {
            id
            title
            handle
            products(first: 250) {
                nodes {
                    handle
                }
            }
        }
    }
`;
export default collectionByHandleProductHandlesOnly;
