import React from 'react';
import s from './input.module.css';
import cn from 'clsx';

export type InputProps = {
    type: string;
    id: string;
    name: string;
    placeholder?: string;
    pattern?: string;
    required?: boolean;
    onChange?: (value: string) => void;
    defaultValue?: string;
    labelText: string;
};

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
    (
        {
            type,
            id,
            placeholder,
            pattern,
            required,
            onChange,
            defaultValue,
            labelText,
            ...rest
        },
        ref
    ) => {
        const handleChange = (event) => {
            if (onChange) onChange(event?.target?.value);
        };

        const labelClassNames = cn('mmds-component-one-detail');
        const inputClassNames = cn(s.input, 'mmds-component-one');
        return (
            <div>
                <label htmlFor={id} className={labelClassNames}>
                    {labelText}
                </label>
                <input
                    ref={ref}
                    type={type}
                    id={id}
                    className={inputClassNames}
                    placeholder={placeholder}
                    pattern={pattern}
                    required={required}
                    onChange={handleChange}
                    defaultValue={defaultValue}
                    {...rest}
                />
            </div>
        );
    }
);
Input.displayName = 'Input';
export default Input;
