import { gql } from 'graphql-request';
import { COLLECTION_PRODUCT_FIELDS } from '@app/lib/shopify/queries/fragments/collection-product-fields';

const collectionByHandle = gql`
    query CollectionQuery(
        $country: CountryCode
        $handle: String!
        $filters: [ProductFilter!]
        $first: Int
        $after: String
        $sortKey: ProductCollectionSortKeys
        $reverse: Boolean
    ) @inContext(country: $country) {
        collection(handle: $handle) {
            handle
            title
            description
            descriptionHtml
            seo {
                title
                description
            }
            image {
                altText
                transformedSrc(maxWidth: 700)
            }
            hasPrismicContent: metafield(
                key: "contains_prismic_component"
                namespace: "custom"
            ) {
                value
            }
            hasDescriptionContentBlock: metafield(
                key: "description_copy_content_block"
                namespace: "custom"
            ) {
                value
            }
            products(
                first: $first
                filters: $filters
                after: $after
                sortKey: $sortKey
                reverse: $reverse
            ) {
                edges {
                    cursor
                    node {
                        ...CollectionProductFields
                    }
                }
                filters {
                    id
                    label
                    type
                    values {
                        id
                        label
                        count
                        input
                    }
                }
                pageInfo {
                    hasNextPage
                }
            }
        }
    }
    ${COLLECTION_PRODUCT_FIELDS}
`;
export default collectionByHandle;
