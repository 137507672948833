// This is the production config
import { Environment } from '@app/lib/store-context/Context';

const production: Environment = {
    contexts: [
        {
            countryCode: 'AU',
            language: 'en-AU',
            currencyCode: 'AUD',
            shopifyDomain: 'maapapparel.myshopify.com',
            shopifyBrandedDomain: 'maapintl.maap.cc',
            shopifyStorefrontToken: '500bc9fa57d0e0d1100dbbd63da079f9',
            shopifyMarketCountryCode: 'AU',
            gtmContainerID: 'GTM-MM7SMP',
            klaviyoCompanyID: 'an79Sm',
            justUNOID: 'E2B3CB0F-64E3-4DF9-9890-36197C1121A8',
            loopApiKey: '5993c78331f6f361e63199aa4c5fa0caa4153b80',
            nostoAccountID: 'shopify-5107809',
            tiktokTrackingCode: 'CFQO3CBC77UBIS8PJ4MG',
            pinterestTagId: '2612528985582',
            metaPixelID: '170266553326174',
        },
        {
            countryCode: 'US',
            language: 'en-US',
            currencyCode: 'USD',
            shopifyDomain: 'maap-usa.myshopify.com',
            shopifyBrandedDomain: 'maapus.maap.cc',
            shopifyStorefrontToken: '30f853242eb81fc4de306a58e2f959d8',
            shopifyMarketCountryCode: null,
            gtmContainerID: 'GTM-P533ZR',
            klaviyoCompanyID: 'spuDKT',
            justUNOID: 'A75CCE1D-1B46-413A-A33D-6A775FF746F5',
            loopApiKey: 'cba32d78e5d33b3c2d6678e671e15f4a1f3321a7',
            nostoAccountID: 'shopify-14318222',
            metaPixelID: '170266553326174',
        },
        {
            countryCode: 'EU',
            language: 'en-EU',
            currencyCode: 'EUR',
            shopifyDomain: 'maap-eu.myshopify.com',
            shopifyBrandedDomain: 'maapeu.maap.cc',
            shopifyStorefrontToken: '84b0e23a57ca2ba6307896b2ad2e9f5a',
            shopifyMarketCountryCode: null,
            gtmContainerID: 'GTM-58N9VRS',
            klaviyoCompanyID: 'K5Bis4',
            justUNOID: '0E16CF97-28B4-4576-AB4F-48FF09D14C38',
            loopApiKey: '7323e4a3c1e5165077876f377d05b83509549c93',
            nostoAccountID: 'shopify-21803833',
            metaPixelID: '170266553326174',
        },
        {
            countryCode: 'UK',
            language: 'en-UK',
            currencyCode: 'GBP',
            shopifyDomain: 'maap-uk.myshopify.com',
            shopifyBrandedDomain: 'maapuk.maap.cc',
            shopifyStorefrontToken: '1281400f87990117c7e16ed5e7636c83',
            shopifyMarketCountryCode: null,
            gtmContainerID: 'GTM-N9BKK9N',
            klaviyoCompanyID: 'YwMLxd',
            justUNOID: 'BD0B9D97-5533-408C-860B-5C1ED134DD93',
            loopApiKey: 'c99106a716c8b9ab07940280a6124ea570c77082',
            nostoAccountID: 'shopify-57054462121',
            metaPixelID: '170266553326174',
        },
        {
            countryCode: 'SG',
            language: 'en-SG',
            currencyCode: 'SGD',
            shopifyDomain: 'maapapparel.myshopify.com',
            shopifyBrandedDomain: 'maapintl.maap.cc',
            shopifyStorefrontToken: '500bc9fa57d0e0d1100dbbd63da079f9',
            shopifyMarketCountryCode: 'SG',
            gtmContainerID: 'GTM-MM7SMP',
            klaviyoCompanyID: 'an79Sm',
            justUNOID: 'E2B3CB0F-64E3-4DF9-9890-36197C1121A8',
            loopApiKey: '5993c78331f6f361e63199aa4c5fa0caa4153b80',
            nostoAccountID: 'shopify-5107809',
            tiktokTrackingCode: 'CFQO3CBC77UBIS8PJ4MG',
            pinterestTagId: '2612528985582',
            metaPixelID: '170266553326174',
        },
        {
            countryCode: 'TW',
            language: 'en-TW',
            currencyCode: 'TWD',
            shopifyDomain: 'maapapparel.myshopify.com',
            shopifyBrandedDomain: 'maapintl.maap.cc',
            shopifyStorefrontToken: '500bc9fa57d0e0d1100dbbd63da079f9',
            shopifyMarketCountryCode: 'TW',
            gtmContainerID: 'GTM-MM7SMP',
            klaviyoCompanyID: 'an79Sm',
            justUNOID: 'E2B3CB0F-64E3-4DF9-9890-36197C1121A8',
            loopApiKey: '5993c78331f6f361e63199aa4c5fa0caa4153b80',
            nostoAccountID: 'shopify-5107809',
            tiktokTrackingCode: 'CFQO3CBC77UBIS8PJ4MG',
            pinterestTagId: '2612528985582',
            metaPixelID: '170266553326174',
        },
        {
            countryCode: 'HK',
            language: 'en-HK',
            currencyCode: 'HKD',
            shopifyDomain: 'maapapparel.myshopify.com',
            shopifyBrandedDomain: 'maapintl.maap.cc',
            shopifyStorefrontToken: '500bc9fa57d0e0d1100dbbd63da079f9',
            shopifyMarketCountryCode: 'HK',
            gtmContainerID: 'GTM-MM7SMP',
            klaviyoCompanyID: 'an79Sm',
            justUNOID: 'E2B3CB0F-64E3-4DF9-9890-36197C1121A8',
            loopApiKey: '5993c78331f6f361e63199aa4c5fa0caa4153b80',
            nostoAccountID: 'shopify-5107809',
            tiktokTrackingCode: 'CFQO3CBC77UBIS8PJ4MG',
            pinterestTagId: '2612528985582',
            metaPixelID: '170266553326174',
        },
    ],
    services: {
        algolia: {
            applicationID: '9KZ3AQR8PN',
            apiKey: '0fce7a190e07c0e23b86cffd188fbc2e',
        },
        zendesk: {
            key: '88e48c99-2339-48c8-86f2-45da47af6e2f',
        },
    },
};

export default production;
