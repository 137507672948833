import React, { ReactNode } from 'react';
import cn from 'clsx';
import { CTASet, PatternCTASet } from '../cta-set/PatternCTASet';
import { hasLongWord } from './has-long-word';
import s from './PatternPosterData.module.css';

interface PatternPosterData {
    productTitle?: string;
    context?: string;
    content?: string | ReactNode;
    ctaSet?: CTASet;
    variant: 'feature' | 'featureVideoStack' | 'features';
    titlePosition?: 'top' | 'bottom';
    title?: string;
    lang: string;
}

export const PatternPosterData = ({
    productTitle,
    content,
    ctaSet,
    variant,
    context,
    title,
    titlePosition,
    lang,
}: PatternPosterData) => {
    const posterDataClassName = cn({
        [s.feature]: variant === 'feature',
        [s.featureStack]: variant === 'featureVideoStack',
        [s.features]: variant === 'features',
    });

    const defaultTitlePosition = titlePosition || 'bottom';

    const titleClassName = cn({
        [s.title]: true,
        [s.titleTop]: titlePosition === 'top',
        [s.titleBottom]: defaultTitlePosition === 'bottom',
        [s.longWord]: title ? hasLongWord(title) : false,
    });

    return (
        <div className={posterDataClassName}>
            <div>
                {context && (
                    <div className={cn('mmds-component-one-detail')}>
                        {context}
                    </div>
                )}
                {title && titlePosition === 'top' && (
                    <div className={cn(titleClassName)}>{title}</div>
                )}
                {productTitle && (
                    <div className={cn('mmds-component-one-detail')}>
                        {productTitle}
                    </div>
                )}
            </div>
            <div>
                {title && defaultTitlePosition === 'bottom' && (
                    <div className={cn(titleClassName)}>{title}</div>
                )}
                {content && <div className={s.content}>{content}</div>}
                {ctaSet?.[0]?.label && (
                    <div className={s.button}>
                        <PatternCTASet set={ctaSet} lang={lang} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default PatternPosterData;
